<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        md8
        style="margin-bottom:80px;max-width:1000px;"
        class="text-center"
      >
        <v-icon class="icon">
          mdi-note-search-outline
        </v-icon>
        <div class="text-h3 mt-5">
          お探しのページは見つかりませんでした。
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Container'
}
</script>

<style lang="scss" scoped>
.icon {
  font-size: 15rem;
}
</style>
